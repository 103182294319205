import { Link } from 'react-router-dom';

import HamburgerButton from './HamburgerButton';

import './Banner.css';

const banner = (props) => {
  return (
    <header>
      <nav className='banner'>
        {/*         <div className='banner_toggleButton'>
          <HamburgerButton click={props.hamburgerClicked} />
        </div> */}
        <img
          className='reinaPic'
          src='images/banner_reina.png'
          alt='reina roja pic'
        ></img>
        <div className='bannerText'>
          <a href='/'>OPM Netrunner</a>
        </div>
        <img
          className='frogPic'
          src='images/banner_cyberfrog.png'
          alt='cyberfrog pic'
        ></img>
        <div className='nav-itemsWrapper'>
          <Link to='/anarch' className='nav__anarch'>
            <img src='images/icon_anarch.svg' />
            <div className='linkText'>ANARCH</div>
          </Link>
          <Link to='/criminal' className='nav__criminal'>
            <img src='images/icon_criminal.svg' />
            <div className='linkText'>CRIMINAL</div>
          </Link>
          <Link to='/shaper' className='nav__shaper'>
            <img src='images/icon_shaper.svg' />
            <div className='linkText'>SHAPER</div>
          </Link>
          <Link to='/runnerneutral' className='nav__runner-neutral'>
            <img src='images/icon_runner-neutral.svg' />
            <div className='linkText'>RUNNER NEUTRAL</div>
          </Link>
          <Link to='/hb' className='nav__hb'>
            <img src='images/icon_hb.svg' />
            <div className='linkText'>HAAS-BIOROID</div>
          </Link>
          <Link to='/jinteki' className='nav__jinteki'>
            <img src='images/icon_jinteki.svg' />
            <div className='linkText'>JINTEKI</div>
          </Link>
          <Link to='/nbn' className='nav__nbn'>
            <img src='images/icon_nbn.svg' />
            <div className='linkText'>NBN</div>
          </Link>
          <Link to='/weyland' className='nav__weyland'>
            <img src='images/icon_weyland.svg' />
            <div className='linkText'>WEYLAND</div>
          </Link>
          <Link to='/corpneutral' className='nav__corp-neutral'>
            <img src='images/icon_corp-neutral.svg' />
            <div className='linkText'>CORP NEUTRAL</div>
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default banner;
