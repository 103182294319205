import './CardDisplay.css';

const corpneutral = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  const newCard = {
    border: '5px dashed rgba(0, 128, 0)',
  };
  return (
    <>
      <div className='backgroundCorpNeutral'></div>
      <main className='wrapperAllCards'>
        <h1>AGENDAS</h1>
        <div className='wrapperCardType'>
          <img
            alt='priority requisition'
            src='https://netrunnerdb.com/card_image/large/01106.jpg'
          />
          <img
            alt='private security force'
            src='https://netrunnerdb.com/card_image/large/01107.jpg'
          />
          <img
            alt='false lead'
            src='https://netrunnerdb.com/card_image/large/02080.jpg'
          />
          <img
            alt='corporate war'
            src='https://netrunnerdb.com/card_image/large/02120.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ICE</h1>
        <div className='wrapperCardType'>
          <img
            alt='enigma'
            src='https://netrunnerdb.com/card_image/large/01111.jpg'
          />
          <img
            alt='hunter'
            src='https://netrunnerdb.com/card_image/large/01112.jpg'
          />
          <img
            alt='wall of static'
            src='https://netrunnerdb.com/card_image/large/01113.jpg'
          />
          <img
            alt='owl'
            src='https://netrunnerdb.com/card_image/large/12060.jpg'
            style={newCard}
          />
          <img
            alt='conundrum'
            src='https://netrunnerdb.com/card_image/large/12120.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ASSETS</h1>
        <div className='wrapperCardType'>
          <img
            alt='melange mining corp'
            src='https://netrunnerdb.com/card_image/large/01108.jpg'
          />
          <img
            alt='pad campaign'
            src='https://netrunnerdb.com/card_image/large/01109.jpg'
          />
          <img
            alt='levy university'
            src='https://netrunnerdb.com/card_image/large/03024.jpg'
            style={newCard}
          />
          <img
            alt='sandburg'
            src='https://netrunnerdb.com/card_image/large/11020.jpg'
            style={newCard}
          />
          <img
            alt='technoco'
            src='https://netrunnerdb.com/card_image/large/21060.jpg'
          />
        </div>
        <br />
        <h1>UPGRADES</h1>
        <div className='wrapperCardType'>
          <img
            alt='will-o-the-wisp'
            src='https://netrunnerdb.com/card_image/large/06032.jpg'
          />
        </div>
        <br />
        <h1>OPERATIONS</h1>
        <div className='wrapperCardType'>
          <img
            alt='hedge fund'
            src='https://netrunnerdb.com/card_image/large/01110.jpg'
          />
          <img
            alt='localized product line'
            src='https://netrunnerdb.com/card_image/large/10075.jpg'
            style={newCard}
          />
        </div>
      </main>
    </>
  );
};

export default corpneutral;
