import './CardDisplay.css';

const anarch = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  return (
    <>
      <div className='backgroundAnarch'></div>
      <main className='wrapperAllCards'>
        <h1>IDENTITIES</h1>
        <div className='wrapperCardType'>
          <img
            alt='noise'
            src='https://netrunnerdb.com/card_image/large/01001.jpg'
          />
          <img
            alt='reina roja'
            src='https://netrunnerdb.com/card_image/large/04041.jpg'
          />
        </div>
        <br />
        <h1>PROGRAMS</h1>
        <div className='wrapperCardType'>
          <img
            alt='wyrm'
            src='https://netrunnerdb.com/card_image/large/01013.jpg'
          />
          <img
            alt='darwin'
            src='https://netrunnerdb.com/card_image/large/02102.jpg'
          />
          <img
            alt='nerve agent'
            src='https://netrunnerdb.com/card_image/large/02041.jpg'
          />
          <img
            alt='force of nature'
            src='https://netrunnerdb.com/card_image/large/02062.jpg'
          />
          <img
            alt='morning star'
            src='https://netrunnerdb.com/card_image/large/02004.jpg'
          />
          <img
            alt='mimic'
            src='https://netrunnerdb.com/card_image/large/01011.jpg'
          />
          <img
            alt='djinn'
            src='https://netrunnerdb.com/card_image/large/01009.jpg'
          />
          <img
            alt='hemorrhage'
            src='https://netrunnerdb.com/card_image/large/04082.jpg'
          />
          <img
            alt='imp'
            src='https://netrunnerdb.com/card_image/large/02003.jpg'
          />
          <img
            alt='parasite'
            src='https://netrunnerdb.com/card_image/large/01012.jpg'
            style={specialCard}
          />
        </div>
        <br />
        <h1>HARDWARE</h1>
        <div className='wrapperCardType'>
          <img
            alt='spinal modem'
            src='https://netrunnerdb.com/card_image/large/02002.jpg'
          />
          <img
            alt='cyberfeeder'
            src='https://netrunnerdb.com/card_image/large/01005.jpg'
          />
        </div>
        <br />
        <h1>RESOURCES</h1>
        <div className='wrapperCardType'>
          <img
            alt='scrubber'
            src='https://netrunnerdb.com/card_image/large/02063.jpg'
          />
          <img
            alt='wyldside'
            src='https://netrunnerdb.com/card_image/large/01016.jpg'
          />
          <img
            alt='ice carver'
            src='https://netrunnerdb.com/card_image/large/01015.jpg'
          />
          <img
            alt='xanadu'
            src='https://netrunnerdb.com/card_image/large/02082.jpg'
          />
          <img
            alt='liberated account'
            src='https://netrunnerdb.com/card_image/large/02022.jpg'
          />
        </div>
        <br />
        <h1>EVENTS</h1>
        <div className='wrapperCardType'>
          <img
            alt='retrieval run'
            src='https://netrunnerdb.com/card_image/large/02101.jpg'
          />
          <img
            alt='stimhack'
            src='https://netrunnerdb.com/card_image/large/01004.jpg'
          />
          <img
            alt='demolition run'
            src='https://netrunnerdb.com/card_image/large/01003.jpg'
          />
          <img
            alt='singularity'
            src='https://netrunnerdb.com/card_image/large/04101.jpg'
          />
          <img
            alt='deja vu'
            src='https://netrunnerdb.com/card_image/large/01002.jpg'
          />
          <img
            alt='surge'
            src='https://netrunnerdb.com/card_image/large/02081.jpg'
          />
        </div>
      </main>
    </>
  );
};

export default anarch;
