import './CardDisplay.css';

const home = (props) => {
  return (
    <main className='wrapperAllCards'>
      <h1>HOME</h1>
      <br />
      <a href='/files/CardPoolv1-01.pdf'>
        CLICK HERE for a text-only version of the latest card pool
      </a>
    </main>
  );
};

export default home;
