import './CardDisplay.css';

const hb = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  const newCard = {
    border: '5px dashed rgba(0, 128, 0)',
  };
  return (
    <>
      <div className='backgroundHB'></div>
      <main className='wrapperAllCards'>
        <h1>IDENTITIES</h1>
        <div className='wrapperCardType'>
          <img
            alt='next design'
            src='https://netrunnerdb.com/card_image/large/03003.jpg'
          />
          <img
            alt='asa group'
            src='https://netrunnerdb.com/card_image/large/21009.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>AGENDAS</h1>
        <div className='wrapperCardType'>
          <img
            alt='accelerated beta test'
            src='https://netrunnerdb.com/card_image/large/01055.jpg'
          />
          <img
            alt='mandatory upgrades'
            src='https://netrunnerdb.com/card_image/large/02011.jpg'
          />
          <img
            alt='director haas pet project'
            src='https://netrunnerdb.com/card_image/large/03004.jpg'
            style={specialCard}
          />
          <img
            alt='efficiency committee'
            src='https://netrunnerdb.com/card_image/large/03005.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ICE</h1>
        <div className='wrapperCardType'>
          <img
            alt='heimdall 1.0'
            src='https://netrunnerdb.com/card_image/large/01061.jpg'
          />
          <img
            alt='ichi 1.0'
            src='https://netrunnerdb.com/card_image/large/01062.jpg'
          />
          <img
            alt='viktor 1.0'
            src='https://netrunnerdb.com/card_image/large/01063.jpg'
          />
          <img
            alt='rototurret'
            src='https://netrunnerdb.com/card_image/large/01064.jpg'
          />
          <img
            alt='hudson 1.0'
            src='https://netrunnerdb.com/card_image/large/04051.jpg'
          />
          <img
            alt='next opal'
            src='https://netrunnerdb.com/card_image/large/12050.jpg'
            style={newCard}
          />
          <img
            alt='next sapphire'
            src='https://netrunnerdb.com/card_image/large/21050.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ASSETS</h1>
        <div className='wrapperCardType'>
          <img
            alt='adonis campaign'
            src='https://netrunnerdb.com/card_image/large/01056.jpg'
          />
          <img
            alt='aggressive secretary'
            src='https://netrunnerdb.com/card_image/large/01057.jpg'
          />
          <img
            alt='thomas haas'
            src='https://netrunnerdb.com/card_image/large/03012.jpg'
            style={newCard}
          />
          <img
            alt='it department'
            src='https://netrunnerdb.com/card_image/large/06103.jpg'
            style={newCard}
          />
          <img
            alt='brain-taping warehouse'
            src='https://netrunnerdb.com/card_image/large/08010.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>UPGRADES</h1>
        <div className='wrapperCardType'>
          <img
            alt='corporate troubleshooter'
            src='https://netrunnerdb.com/card_image/large/01065.jpg'
          />
          <img
            alt='experiential data'
            src='https://netrunnerdb.com/card_image/large/01066.jpg'
          />
          <img
            alt='ash 2x3zb9cy'
            src='https://netrunnerdb.com/card_image/large/02013.jpg'
          />
          <img
            alt='strongbox'
            src='https://netrunnerdb.com/card_image/large/04091.jpg'
          />
          <img
            alt='manta grid'
            src='https://netrunnerdb.com/card_image/large/11091.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>OPERATIONS</h1>
        <div className='wrapperCardType'>
          <img
            alt='archived memories'
            src='https://netrunnerdb.com/card_image/large/01058.jpg'
          />
          <img
            alt='biotic labor'
            src='https://netrunnerdb.com/card_image/large/01059.jpg'
            style={specialCard}
          />
          <img
            alt='shipment from mirrormorph'
            src='https://netrunnerdb.com/card_image/large/01060.jpg'
          />
          <img
            alt='green level clearance'
            src='https://netrunnerdb.com/card_image/large/02070.jpg'
          />
          <img
            alt='product recall'
            src='https://netrunnerdb.com/card_image/large/10029.jpg'
            style={newCard}
          />
          <img
            alt='riot suppression'
            src='https://netrunnerdb.com/card_image/large/21113.jpg'
          />
        </div>
      </main>
    </>
  );
};

export default hb;
