import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import Banner from './Navigation/Banner';
import SideDrawer from './Navigation/SideDrawer';
import Backdrop from './UI/Backdrop';

import About from './PublicPages/About';
import Anarch from './PublicPages/Anarch';
import CorpNeutral from './PublicPages/CorpNeutral';
import Criminal from './PublicPages/Criminal';
import HB from './PublicPages/HB';
import Home from './PublicPages/Home';
import Jinteki from './PublicPages/Jinteki';
import NBN from './PublicPages/NBN';
import RunnerNeutral from './PublicPages/RunnerNeutral';
import Shaper from './PublicPages/Shaper';
import Weyland from './PublicPages/Weyland';

import './App.css';
import './PublicPages/CardDisplay.css';

function App() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const hamburgerClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  const mainListClickHander = () => {
    setSideDrawerOpen(false);
  };

  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop clickBackdrop={backdropClickHandler} />;
  }

  return (
    <>
      <Banner hamburgerClicked={hamburgerClickHandler} />
      <SideDrawer
        showSideDrawer={sideDrawerOpen}
        clickMainList={mainListClickHander}
      />
      {backdrop}
      <div className='app_body'>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/anarch' element={<Anarch />} />
          <Route path='/criminal' element={<Criminal />} />
          <Route path='/shaper' element={<Shaper />} />
          <Route path='/runnerneutral' element={<RunnerNeutral />} />
          <Route path='/hb' element={<HB />} />
          <Route path='/jinteki' element={<Jinteki />} />
          <Route path='/nbn' element={<NBN />} />
          <Route path='/weyland' element={<Weyland />} />
          <Route path='/corpneutral' element={<CorpNeutral />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
