import './CardDisplay.css';

const shaper = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  return (
    <>
      <div className='backgroundShaper'></div>
      <main className='wrapperAllCards'>
        <h1>IDENTITIES</h1>
        <div className='wrapperCardType'>
          <img
            alt='chaos theory'
            src='https://netrunnerdb.com/card_image/large/02046.jpg'
          />
          <img
            alt='kit'
            src='https://netrunnerdb.com/card_image/large/03028.jpg'
          />
        </div>
        <br />
        <h1>PROGRAMS</h1>
        <div className='wrapperCardType'>
          <img
            alt='gordian blade'
            src='https://netrunnerdb.com/card_image/large/01043.jpg'
          />
          <img
            alt='zu.13'
            src='https://netrunnerdb.com/card_image/large/02007.jpg'
          />
          <img
            alt='battering ram'
            src='https://netrunnerdb.com/card_image/large/01042.jpg'
          />
          <img
            alt='pipeline'
            src='https://netrunnerdb.com/card_image/large/01046.jpg'
          />
          <img
            alt='creeper'
            src='https://netrunnerdb.com/card_image/large/02089.jpg'
          />
          <img
            alt='magnum opus'
            src='https://netrunnerdb.com/card_image/large/01044.jpg'
          />
          <img
            alt='net shield'
            src='https://netrunnerdb.com/card_image/large/01045.jpg'
          />
          <img
            alt='inversificator'
            src='https://netrunnerdb.com/card_image/large/12048.jpg'
            style={specialCard}
          />
        </div>
        <br />
        <h1>HARDWARE</h1>
        <div className='wrapperCardType'>
          <img
            alt='dinosaurus'
            src='https://netrunnerdb.com/card_image/large/02048.jpg'
          />
          <img
            alt='toolbox'
            src='https://netrunnerdb.com/card_image/large/01041.jpg'
          />
          <img
            alt='llds processor'
            src='https://netrunnerdb.com/card_image/large/04066.jpg'
          />
          <img
            alt='rabbit hole'
            src='https://netrunnerdb.com/card_image/large/01039.jpg'
          />
          <img
            alt='personal touch'
            src='https://netrunnerdb.com/card_image/large/01040.jpg'
          />
        </div>
        <br />
        <h1>RESOURCES</h1>
        <div className='wrapperCardType'>
          <img
            alt='aesops pawnshop'
            src='https://netrunnerdb.com/card_image/large/01047.jpg'
          />
          <img
            alt='sacrificial construct'
            src='https://netrunnerdb.com/card_image/large/01048.jpg'
          />
          <img
            alt='all-nighter'
            src='https://netrunnerdb.com/card_image/large/02067.jpg'
          />
        </div>
        <br />
        <h1>EVENTS</h1>
        <div className='wrapperCardType'>
          <img
            alt='modded'
            src='https://netrunnerdb.com/card_image/large/01035.jpg'
          />
          <img
            alt='tinkering'
            src='https://netrunnerdb.com/card_image/large/01037.jpg'
          />
          <img
            alt='indexing'
            src='https://netrunnerdb.com/card_image/large/02106.jpg'
            style={specialCard}
          />
          <img
            alt='makers eye'
            src='https://netrunnerdb.com/card_image/large/01036.jpg'
          />
          <img
            alt='diesel'
            src='https://netrunnerdb.com/card_image/large/01034.jpg'
          />
          <img
            alt='notoriety'
            src='https://netrunnerdb.com/card_image/large/02026.jpg'
          />
          <img
            alt='test run'
            src='https://netrunnerdb.com/card_image/large/02047.jpg'
          />
        </div>
      </main>
    </>
  );
};

export default shaper;
