import './CardDisplay.css';

const about = (props) => {
  return (
    <main className='wrapperAllCards'>
      <h1>IDENTITIES</h1>
      <div className='wrapperCardType'>
        <img src='https://netrunnerdb.com/card_image/large/01001.jpg' />
      </div>
    </main>
  );
};

export default about;
