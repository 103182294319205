import './CardDisplay.css';

const weyland = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  const newCard = {
    border: '5px dashed rgba(0, 128, 0)',
  };
  return (
    <>
      <div className='backgroundWeyland'></div>
      <main className='wrapperAllCards'>
        <h1>IDENTITIES</h1>
        <div className='wrapperCardType'>
          <img
            alt='building a better world'
            src='https://netrunnerdb.com/card_image/large/01093.jpg'
          />
          <img
            alt='builder of nations'
            src='https://netrunnerdb.com/card_image/large/11038.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>AGENDAS</h1>
        <div className='wrapperCardType'>
          <img
            alt='hostile takeover'
            src='https://netrunnerdb.com/card_image/large/01094.jpg'
          />
          <img
            alt='posted bounty'
            src='https://netrunnerdb.com/card_image/large/01095.jpg'
          />
          <img
            alt='project atlas'
            src='https://netrunnerdb.com/card_image/large/02018.jpg'
          />
          <img
            alt='the cleaners'
            src='https://netrunnerdb.com/card_image/large/04036.jpg'
          />
          <img
            alt='government takeover'
            src='https://netrunnerdb.com/card_image/large/07006.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ICE</h1>
        <div className='wrapperCardType'>
          <img
            alt='archer'
            src='https://netrunnerdb.com/card_image/large/01101.jpg'
          />
          <img
            alt='hadrians wall'
            src='https://netrunnerdb.com/card_image/large/01102.jpg'
          />
          <img
            alt='ice wall'
            src='https://netrunnerdb.com/card_image/large/01103.jpg'
          />
          <img
            alt='shadow'
            src='https://netrunnerdb.com/card_image/large/01104.jpg'
          />
          <img
            alt='caduceus'
            src='https://netrunnerdb.com/card_image/large/02019.jpg'
          />
          <img
            alt='hive'
            src='https://netrunnerdb.com/card_image/large/04117.jpg'
          />
          <img
            alt='asteroid belt'
            src='https://netrunnerdb.com/card_image/large/07012.jpg'
            style={newCard}
          />
          <img
            alt='tour guide'
            src='https://netrunnerdb.com/card_image/large/08118.jpg'
            style={newCard}
          />
          <img
            alt='surveyor'
            src='https://netrunnerdb.com/card_image/large/21118.jpg'
          />
        </div>
        <br />
        <h1>ASSETS</h1>
        <div className='wrapperCardType'>
          <img
            alt='security subcontract'
            src='https://netrunnerdb.com/card_image/large/01096.jpg'
          />
          <img
            alt='dedicated response team'
            src='https://netrunnerdb.com/card_image/large/02118.jpg'
          />
          <img
            alt='elizabeth mills'
            src='https://netrunnerdb.com/card_image/large/04037.jpg'
          />
          <img
            alt='grndl refinery'
            src='https://netrunnerdb.com/card_image/large/04099.jpg'
          />
          <img
            alt='executive boot camp'
            src='https://netrunnerdb.com/card_image/large/06088.jpg'
            style={newCard}
          />
          <img
            alt='capital investors'
            src='https://netrunnerdb.com/card_image/large/08018.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>UPGRADES</h1>
        <div className='wrapperCardType'>
          <img
            alt='research station'
            src='https://netrunnerdb.com/card_image/large/01105.jpg'
          />
          <img
            alt='expo grid'
            src='https://netrunnerdb.com/card_image/large/08119.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>OPERATIONS</h1>
        <div className='wrapperCardType'>
          <img
            alt='beanstalk royalties'
            src='https://netrunnerdb.com/card_image/large/01098.jpg'
          />
          <img
            alt='scorched earth'
            src='https://netrunnerdb.com/card_image/large/01099.jpg'
            style={specialCard}
          />
          <img
            alt='shipment from kaguya'
            src='https://netrunnerdb.com/card_image/large/01100.jpg'
          />
          <img
            alt='punitive counterstrike'
            src='https://netrunnerdb.com/card_image/large/04079.jpg'
          />
          <img
            alt='sacrifice'
            src='https://netrunnerdb.com/card_image/large/12039.jpg'
            style={newCard}
          />
        </div>
      </main>
    </>
  );
};

export default weyland;
