import './CardDisplay.css';

const criminal = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  return (
    <>
      <div className='backgroundCriminal'></div>
      <main className='wrapperAllCards'>
        <h1>IDENTITIES</h1>
        <div className='wrapperCardType'>
          <img
            alt='gabriel santiago'
            src='https://netrunnerdb.com/card_image/large/01017.jpg'
          />
          <img
            alt='ken tenma'
            src='https://netrunnerdb.com/card_image/large/05029.jpg'
          />
        </div>
        <br />
        <h1>PROGRAMS</h1>
        <div className='wrapperCardType'>
          <img
            alt='peacock'
            src='https://netrunnerdb.com/card_image/large/02006.jpg'
          />
          <img
            alt='aurora'
            src='https://netrunnerdb.com/card_image/large/01025.jpg'
          />
          <img
            alt='femme fatale'
            src='https://netrunnerdb.com/card_image/large/01026.jpg'
          />
          <img
            alt='faerie'
            src='https://netrunnerdb.com/card_image/large/02104.jpg'
          />
          <img
            alt='pheromones'
            src='https://netrunnerdb.com/card_image/large/02086.jpg'
          />
          <img
            alt='sneakdoor beta'
            src='https://netrunnerdb.com/card_image/large/01028.jpg'
          />
          <img
            alt='crescentus'
            src='https://netrunnerdb.com/card_image/large/02065.jpg'
          />
          <img
            alt='expert schedule analyzer'
            src='https://netrunnerdb.com/card_image/large/04045.jpg'
          />
        </div>
        <br />
        <h1>HARDWARE</h1>
        <div className='wrapperCardType'>
          <img
            alt='doppelganger'
            src='https://netrunnerdb.com/card_image/large/02064.jpg'
          />
          <img
            alt='muresh bodysuit'
            src='https://netrunnerdb.com/card_image/large/02044.jpg'
          />
          <img
            alt='hq interface'
            src='https://netrunnerdb.com/card_image/large/02085.jpg'
          />
        </div>
        <br />
        <h1>RESOURCES</h1>
        <div className='wrapperCardType'>
          <img
            alt='decoy'
            src='https://netrunnerdb.com/card_image/large/01032.jpg'
          />
          <img
            alt='fall guy'
            src='https://netrunnerdb.com/card_image/large/04106.jpg'
          />
          <img
            alt='mr li'
            src='https://netrunnerdb.com/card_image/large/02105.jpg'
          />
          <img
            alt='bank job'
            src='https://netrunnerdb.com/card_image/large/01029.jpg'
          />
          <img
            alt='crash space'
            src='https://netrunnerdb.com/card_image/large/01030.jpg'
          />
        </div>
        <br />
        <h1>EVENTS</h1>
        <div className='wrapperCardType'>
          <img
            alt='easy mark'
            src='https://netrunnerdb.com/card_image/large/01019.jpg'
          />
          <img
            alt='inside job'
            src='https://netrunnerdb.com/card_image/large/01021.jpg'
          />
          <img
            alt='account siphon'
            src='https://netrunnerdb.com/card_image/large/01018.jpg'
            style={specialCard}
          />
          <img
            alt='emergency shutdown'
            src='https://netrunnerdb.com/card_image/large/02043.jpg'
          />
          <img
            alt='forged activation orders'
            src='https://netrunnerdb.com/card_image/large/01020.jpg'
          />
          <img
            alt='special order'
            src='https://netrunnerdb.com/card_image/large/01022.jpg'
          />
          <img
            alt='career fair'
            src='https://netrunnerdb.com/card_image/large/08023.jpg'
          />
        </div>
      </main>
    </>
  );
};

export default criminal;
