import './CardDisplay.css';

const jinteki = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  const newCard = {
    border: '5px dashed rgba(0, 128, 0)',
  };
  return (
    <>
      <div className='backgroundJinteki'></div>
      <main className='wrapperAllCards'>
        <h1>IDENTITIES</h1>
        <div className='wrapperCardType'>
          <img
            alt='personal evolution'
            src='https://netrunnerdb.com/card_image/large/01067.jpg'
          />
          <img
            alt='jinteki biotech'
            src='https://netrunnerdb.com/card_image/large/08012.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>AGENDAS</h1>
        <div className='wrapperCardType'>
          <img
            alt='nisei mk ii'
            src='https://netrunnerdb.com/card_image/large/01068.jpg'
          />
          <img
            alt='braintrust'
            src='https://netrunnerdb.com/card_image/large/02014.jpg'
          />
          <img
            alt='fetal ai'
            src='https://netrunnerdb.com/card_image/large/02032.jpg'
          />
          <img
            alt='medical breakthrough'
            src='https://netrunnerdb.com/card_image/large/05005.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ICE</h1>
        <div className='wrapperCardType'>
          <img
            alt='chum'
            src='https://netrunnerdb.com/card_image/large/01075.jpg'
          />
          <img
            alt='data mine'
            src='https://netrunnerdb.com/card_image/large/01076.jpg'
          />
          <img
            alt='neural katana'
            src='https://netrunnerdb.com/card_image/large/01077.jpg'
          />
          <img
            alt='wall of thorns'
            src='https://netrunnerdb.com/card_image/large/01078.jpg'
          />
          <img
            alt='whirlpool'
            src='https://netrunnerdb.com/card_image/large/02094.jpg'
          />
          <img
            alt='himitsu-bako'
            src='https://netrunnerdb.com/card_image/large/04013.jpg'
          />
          <img
            alt='swordsman'
            src='https://netrunnerdb.com/card_image/large/04033.jpg'
          />
          <img
            alt='yagura'
            src='https://netrunnerdb.com/card_image/large/04093.jpg'
          />
          <img
            alt='komainu'
            src='https://netrunnerdb.com/card_image/large/05017.jpg'
            style={newCard}
          />
          <img
            alt='chetana'
            src='https://netrunnerdb.com/card_image/large/10089.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ASSETS</h1>
        <div className='wrapperCardType'>
          <img
            alt='project junebug'
            src='https://netrunnerdb.com/card_image/large/01069.jpg'
          />
          <img
            alt='snare!'
            src='https://netrunnerdb.com/card_image/large/01070.jpg'
          />
          <img
            alt='ronin'
            src='https://netrunnerdb.com/card_image/large/02112.jpg'
          />
          <img
            alt='hostile infrastructure'
            src='https://netrunnerdb.com/card_image/large/06083.jpg'
            style={newCard}
          />
          <img
            alt='hyoubu research facility'
            src='https://netrunnerdb.com/card_image/large/11012.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>UPGRADES</h1>
        <div className='wrapperCardType'>
          <img
            alt='akitaro watanabe'
            src='https://netrunnerdb.com/card_image/large/01079.jpg'
          />
          <img
            alt='hokusai grid'
            src='https://netrunnerdb.com/card_image/large/02095.jpg'
          />
          <img
            alt='midori'
            src='https://netrunnerdb.com/card_image/large/02113.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>OPERATIONS</h1>
        <div className='wrapperCardType'>
          <img
            alt='neural emp'
            src='https://netrunnerdb.com/card_image/large/01072.jpg'
          />
          <img
            alt='trick of light'
            src='https://netrunnerdb.com/card_image/large/02033.jpg'
          />
          <img
            alt='celebrity gift'
            src='https://netrunnerdb.com/card_image/large/04012.jpg'
          />
          <img
            alt='restoring face'
            src='https://netrunnerdb.com/card_image/large/04094.jpg'
            style={newCard}
          />
          <img
            alt='mushin no shin'
            src='https://netrunnerdb.com/card_image/large/05015.jpg'
            style={newCard}
          />
        </div>
      </main>
    </>
  );
};

export default jinteki;
