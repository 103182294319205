import './SideDrawer.css';

const sideDrawer = (props) => {
  let attachedClasses = 'sideDrawer';
  if (props.showSideDrawer) {
    attachedClasses = 'sideDrawer sideDrawerOpen';
  }

  return (
    <nav className={attachedClasses}>
      <ul className='sideDrawer_mainList'>
        <div className='sideDrawer_simple'>
          <li>
            <a href='/' className='sideDrawer_mainList-linkSimple'>
              Home
            </a>
          </li>
        </div>
        <div className='sideDrawer_simple'>
          <li>
            <a href='/about' className='sideDrawer_mainList-linkSimple'>
              About
            </a>
          </li>
        </div>
        <div className='sideDrawer_simple'>
          <li>
            <a href='/upcoming' className='sideDrawer_mainList-linkSimple'>
              Upcoming
            </a>
          </li>
        </div>
        <div className='sideDrawer_simple'>
          <li>
            <a href='/stats' className='sideDrawer_mainList-linkSimple'>
              Stats
            </a>
          </li>
        </div>
        <li className='sideDrawer_mainList-item'>
          Annual Reports
          <ul className='sideDrawer_subList'>
            <li>
              <a href='/report2017'>2017</a>
            </li>
            <li>
              <a href='/report2018'>2018</a>
            </li>
            <li>
              <a href='/report2019'>2019</a>
            </li>
            <li>
              <a href='/upcoming'>2020</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default sideDrawer;
