import './CardDisplay.css';

const runnerneutral = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  return (
    <>
      <div className='backgroundRunnerNeutral'></div>
      <main className='wrapperAllCards'>
        <h1>PROGRAMS</h1>
        <div className='wrapperCardType'>
          <img
            alt='crypsis'
            src='https://netrunnerdb.com/card_image/large/01051.jpg'
          />
        </div>
        <br />
        <h1>HARDWARE</h1>
        <div className='wrapperCardType'>
          <img
            alt='dyson mem chip'
            src='https://netrunnerdb.com/card_image/large/02028.jpg'
          />
        </div>
        <br />
        <h1>RESOURCES</h1>
        <div className='wrapperCardType'>
          <img
            alt='inside man'
            src='https://netrunnerdb.com/card_image/large/02068.jpg'
          />
          <img
            alt='underworld contact'
            src='https://netrunnerdb.com/card_image/large/02069.jpg'
          />
          <img
            alt='armitage codebusting'
            src='https://netrunnerdb.com/card_image/large/01053.jpg'
          />
          <img
            alt='access to globalsec'
            src='https://netrunnerdb.com/card_image/large/01052.jpg'
          />
          <img
            alt='public sympathy'
            src='https://netrunnerdb.com/card_image/large/02050.jpg'
          />
          <img
            alt='starlight crusade funding'
            src='https://netrunnerdb.com/card_image/large/04069.jpg'
          />
        </div>
        <br />
        <h1>EVENTS</h1>
        <div className='wrapperCardType'>
          <img
            alt='infiltration'
            src='https://netrunnerdb.com/card_image/large/01049.jpg'
          />
          <img
            alt='sure gamble'
            src='https://netrunnerdb.com/card_image/large/01050.jpg'
          />
        </div>
      </main>
    </>
  );
};

export default runnerneutral;
