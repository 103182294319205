import './CardDisplay.css';

const nbn = (props) => {
  const specialCard = {
    border: '5px solid rgba(255, 0, 0)',
  };
  const newCard = {
    border: '5px dashed rgba(0, 128, 0)',
  };
  return (
    <>
      <div className='backgroundNBN'></div>
      <main className='wrapperAllCards'>
        <h1>IDENTITIES</h1>
        <div className='wrapperCardType'>
          <img
            alt='the world is yours'
            src='https://netrunnerdb.com/card_image/large/02114.jpg'
          />
          <img
            alt='haarpsichord studios'
            src='https://netrunnerdb.com/card_image/large/08092.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>AGENDAS</h1>
        <div className='wrapperCardType'>
          <img
            alt='astroscript pilot program'
            src='https://netrunnerdb.com/card_image/large/01081.jpg'
            style={specialCard}
          />
          <img
            alt='breaking news'
            src='https://netrunnerdb.com/card_image/large/01082.jpg'
          />
          <img
            alt='project beale'
            src='https://netrunnerdb.com/card_image/large/02115.jpg'
          />
          <img
            alt='TGTBT'
            src='https://netrunnerdb.com/card_image/large/04075.jpg'
          />
          <img
            alt='improved tracers'
            src='https://netrunnerdb.com/card_image/large/09005.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ICE</h1>
        <div className='wrapperCardType'>
          <img
            alt='data raven'
            src='https://netrunnerdb.com/card_image/large/01088.jpg'
          />
          <img
            alt='tollbooth'
            src='https://netrunnerdb.com/card_image/large/01090.jpg'
          />
          <img
            alt='pop-up window'
            src='https://netrunnerdb.com/card_image/large/02056.jpg'
          />
          <img
            alt='flare'
            src='https://netrunnerdb.com/card_image/large/02117.jpg'
          />
          <img
            alt='universal connectivity fee'
            src='https://netrunnerdb.com/card_image/large/06067.jpg'
            style={newCard}
          />
          <img
            alt='troll'
            src='https://netrunnerdb.com/card_image/large/06108.jpg'
            style={newCard}
          />
          <img
            alt='ip block'
            src='https://netrunnerdb.com/card_image/large/11094.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>ASSETS</h1>
        <div className='wrapperCardType'>
          <img
            alt='ghost branch'
            src='https://netrunnerdb.com/card_image/large/01087.jpg'
          />
          <img
            alt='marked accounts'
            src='https://netrunnerdb.com/card_image/large/02055.jpg'
          />
          <img
            alt='broadcast square'
            src='https://netrunnerdb.com/card_image/large/04112.jpg'
            style={newCard}
          />
          <img
            alt='primary transmission dish'
            src='https://netrunnerdb.com/card_image/large/06006.jpg'
          />
          <img
            alt='expose'
            src='https://netrunnerdb.com/card_image/large/08075.jpg'
          />
          <img
            alt='victoria jenkins'
            src='https://netrunnerdb.com/card_image/large/09011.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>UPGRADES</h1>
        <div className='wrapperCardType'>
          <img
            alt='red herrings'
            src='https://netrunnerdb.com/card_image/large/01091.jpg'
          />
          <img
            alt='sansan city grid'
            src='https://netrunnerdb.com/card_image/large/01092.jpg'
            style={specialCard}
          />
          <img
            alt='bernice mai'
            src='https://netrunnerdb.com/card_image/large/02097.jpg'
          />
          <img
            alt='intake'
            src='https://netrunnerdb.com/card_image/large/21098.jpg'
            style={newCard}
          />
        </div>
        <br />
        <h1>OPERATIONS</h1>
        <div className='wrapperCardType'>
          <img
            alt='anonymous tip'
            src='https://netrunnerdb.com/card_image/large/01083.jpg'
          />
          <img
            alt='closed accounts'
            src='https://netrunnerdb.com/card_image/large/01084.jpg'
          />
          <img
            alt='psychographics'
            src='https://netrunnerdb.com/card_image/large/01085.jpg'
          />
          <img
            alt='casting call'
            src='https://netrunnerdb.com/card_image/large/08096.jpg'
            style={newCard}
          />
          <img
            alt='market forces'
            src='https://netrunnerdb.com/card_image/large/21117.jpg'
            style={newCard}
          />
        </div>
      </main>
    </>
  );
};

export default nbn;
